import React from 'react';
import { Link } from 'react-router-dom';
import '../stylesheets/Navbar.css';


function Navbar() {
  const setDocumentTitle = () => {
    document.title = 'UnexploredHQ';
  };

  return (
    <nav className="navbar">
      <div className="links">
        <Link to="/" onClick={setDocumentTitle}>Home</Link>
        {/* You can add more links here */}
      </div>
    </nav>
  );
}

export default Navbar;