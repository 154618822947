import React, { useState } from "react";
import Select from "react-select";

const AsyncSelect = ({ setRegion }) => {
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);

  const loadOptions = async (inputValue) => {
    setLoading(true);
    // Fetch options from the database based on the user's input value
    const query_data = { query_sub_string: inputValue };
    const response = await fetch("/api/find_subset_match/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(query_data),
    });
    const data = await response.json();
    const formattedOptions = Object.values(data.subset_match).map((option) => ({
      value: option,
      label: option,
    }));
    setLoading(false);
    setOptions(formattedOptions);
  };

  const customStyles = {
    input: (provided) => ({
      ...provided,
      color: "white",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: "white",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? "#3c7c44"
        : state.isFocused
        ? "#23332d"
        : provided.backgroundColor,
      color: state.isSelected ? "white" : provided.color,
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      "&:hover": {
        color: "#44cc44",
      },
    }),
    control: (provided) => ({
      ...provided,
      backgroundColor: "black",
    }),
    placeholder: (provided) => ({
      ...provided,
      backgroundColor: "black",
      color: "#808080",
    }),
    menuList: (provided) => ({
      ...provided,
      color: "white",
      backgroundColor: "black",
      height: "fit-content",
      zIndex: 10000,
    }),
  };

  const handleSelect = (selectedOption) => {
    setRegion(selectedOption.value);
    setSelectedOption(selectedOption);
  };

  const handleClick = () => {
    setSelectedOption(null);
    //setOptions([]);
  };

  return (
    <Select
      options={options}
      isLoading={loading}
      styles={customStyles}
      className={"regionInputAsync"}
      placeholder="Enter the region you want to search (eg. Europe)"
      noOptionsMessage={(ref) => {
        return ref.inputValue.length > 0 ? "No match found" : "";
      }}
      onInputChange={(inputValue) => {
        // Only load options if the user has typed at least 3 characters
        if (inputValue.length >= 2) {
          loadOptions(inputValue);
        }
      }}
      onChange={handleSelect}
      onMenuOpen={handleClick}
      value={selectedOption} // Add this line to control the selected option
    />
  );
};

export default AsyncSelect;
