export const Header = ({ classNoDestDisp, onFeedbackClick }) => {
  return (
    <header>
      <div className={`feedback-btn-wrapper ${classNoDestDisp}`}>
        <button className="feedback-btn" onClick={onFeedbackClick}>
          Feedback
        </button>
      </div>
      <h1>
        It's time to explore new <br />
        <div className="scroller">
          <span>
            Destinations
            <br />
            Vacations
            <br />
            Getaways
            <br />
            Experiences
          </span>
        </div>
      </h1>
    </header>
  );
};
export default Header;
