import { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import "../stylesheets/DestinationInfo.css";
import Navbar from '../components/Navbar';
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import { MapContainer, Marker, Popup, TileLayer} from "react-leaflet";

function DestinationInfo() {
  const params = useParams()
  const entry_post_slash = params["*"]; 
  let destinationName = params["destinationName"];
  let displayName = capitalizeFirstLetter(params["destinationName"])

  if (entry_post_slash !== ""){
    // This is useful when destination name was: singapore/riverside. 
    displayName = displayName + '/' + capitalizeFirstLetter(entry_post_slash)
    destinationName = destinationName + '/' + entry_post_slash;
  }

  const [destination_dict, setDestinationDict] = useState({});
  const [isLoading, setIsLoading] = useState(false); // initialize loading state
 
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  // Change the title of the page
  document.title = "UnexploredHQ | " + displayName;

  useEffect(() => {
    setIsLoading(true); // set loading state to true when fetch starts
    fetch("/api/flask_destination_info/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ dest_name: destinationName }),
    })
      .then((response) => response.json())
      .then((response_data) => {
        setDestinationDict(response_data);
      });
  }, [destinationName]);

  // Sort top attributes based on score
  destination_dict.top_attributes = Object.entries(
    destination_dict.top_attributes || {}
  )
    .sort((a, b) => b[1] - a[1])
    .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});

  // Prepare canonical URL
  const location = useLocation();
  const canonicalUrl = window.location.origin + location.pathname;

  // Latitude and Longitude details of the destination
  const [destLat, setDestLat] = useState(39.82);
  const [destLng, setDestLng] = useState(-98.57);

  useEffect(() => {
    if (destination_dict.dest_coords){
      setDestLat(destination_dict.dest_coords.lat);
      setDestLng(destination_dict.dest_coords.lng);
      setIsLoading(false); 
      // set loading state to false when fetch is done, and we have updated the lat/ lng
    }
  }, [destination_dict]);


  // Marker reference for controlling the popup
  const destMarker = useRef(null);

  return (
    <div>
      <Navbar /> 
      <header id="dest-title-h1">
        <h1> {displayName}</h1>
      </header>

      <Helmet>
        <link rel="canonical" href={canonicalUrl} />
      </Helmet>

      <div className="break-line"></div>

      <div className="dest-container">
        {isLoading ? (
            <div className="loading-placeholder"> <p> Loading... </p></div> // Or your preferred loading component
          ) : (
          <>
          <aside className="sidebar sidebar-left">
            <div>
              {Object.keys(destination_dict.top_attributes || {}).length > 0 && (
                <h2>Top Attractions</h2>
              )}
                {Object.entries(destination_dict.top_attributes || {}).map(
                    ([attribute, score], index) => (
                      <span key={attribute}>
                        {attribute}
                        {index !== Object.keys(destination_dict.top_attributes).length - 1 ? ', ' : ''}
                      </span>
                    )
              )}
            </div>

            <div id={"dest-info-map"}>
              <MapContainer whenReady={() => {setTimeout(() => {
                destMarker.current.openPopup();}, 1000);}} 
                center={[destLat, destLng]} zoom={4}>
              <TileLayer
                attribution="Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community"
                url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
              />
              <TileLayer
                attribution='Map tiles by <a href="http://stamen.com">Stamen Design</a>, <a href="http://creativecommons.org/licenses/by/3.0">CC BY 3.0</a> &mdash; Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://stamen-tiles-{s}.a.ssl.fastly.net/toner-hybrid/{z}/{x}/{y}{r}.png"
              />
              <Marker
                position={[destLat, destLng]}
                ref={destMarker} 
              >
                <Popup>
                  {destination_dict.destination}{" "}
                </Popup>
              </Marker>
              </MapContainer>
            </div>                 
          </aside>

          <section className="dest-content">
            <h2> Best time to visit</h2>
            <div id='best-time-visit'>
              <p>{destination_dict.best_time_visit}</p>
            </div>
            <h2>Things to do</h2>
            <div id='things-to-do'>
            <p>{destination_dict.content}</p>
            </div>
 
          </section>
          </>
          )
        }
      </div>
    </div>
  );
}

export default DestinationInfo;
