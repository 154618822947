import Header from "./components/Header";
import InputForm from "./components/InputForm";

import SearchList from "./components/SearchList";
import background_image from "./data/forest_1_compressed.jpeg";
import FeedbackComponent from "./components/FeedbackComponent";

import {lazy, Suspense, useState, useRef, useEffect } from "react";
const Map = lazy(() => import('./components/MapContainer'));



function App() {
  const [destinations, setDestinations] = useState([]);

  const [selected_destination, setSelectedDestination] = useState("");
  const [showRailways, setShowRailways] = useState(false);

  const containerRef = useRef(null);
  const mapRef = useRef(null);
  const [isSearchSubmitted, setIsSearchSubmitted] = useState(false);

  const [displayTempRange, setDisplayTempRange] = useState(false);
  const [temperature, setTemperature] = useState({
    min: parseFloat(15),
    max: parseFloat(25),
    month: "January",
  });

  const [showFeedback, setShowFeedback] = useState(false);

  // Set the scroll top top when destination list changes
  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTop = 0;
    }
  }, [destinations]);

  // Class to toggle display off if we have no destinations loaded up
  const classNoDestDisp =
    destinations.length === 0 ? "NoDestinationDispHidden" : "";

  return (
    <div className="App">
      <FeedbackComponent
        show={showFeedback}
        onClose={() => setShowFeedback(false)}
      />
      <div
        className={`container ${isSearchSubmitted ? "search-submitted" : ""}`}
        style={{
          backgroundImage: `url(${background_image})`,
          opacity: 1.0,
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Header
          classNoDestDisp={classNoDestDisp}
          onFeedbackClick={() => setShowFeedback(true)}
        />
        <InputForm
          destinations={destinations}
          setDestinations={setDestinations}
          temperature={temperature}
          setTemperature={setTemperature}
          displayTempRange={displayTempRange}
          setDisplayTempRange={setDisplayTempRange}
          showRailways={showRailways}
          setShowRailways={setShowRailways}
          setIsSearchSubmitted={setIsSearchSubmitted}
        />
        <div className={`grid-container ${classNoDestDisp}`}>
          <div className={"map"} ref={mapRef}>
            <Suspense fallback={<div>Loading map...</div>}>
                <Map
                  destinations={destinations}
                  selected_destination={selected_destination}
                  setSelectedDestination={setSelectedDestination}
                  showRailways={showRailways}
                />
            </Suspense>
          </div>
          <div ref={containerRef}>
            <SearchList
              destinations={destinations}
              selected_destination={selected_destination}
              setSelectedDestination={setSelectedDestination}
              temperature={temperature}
              displayTempRange={displayTempRange}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
