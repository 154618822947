import { useRef, useEffect } from "react";

const SearchList = ({
  destinations,
  selected_destination,
  setSelectedDestination,
  temperature,
  displayTempRange,
}) => {
  const capitalize = (input_string) => {
    return input_string
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const listRefs = useRef({});
  useEffect(() => {
    if (listRefs.current[selected_destination]) {
      //console.log(listRefs);
      listRefs.current[selected_destination].scrollIntoView({
        behavior: "smooth",
        block: "start",
        duration: 0,
      });
    }
  }, [selected_destination]);

  return (
    <div
      id={"search-result-container"}
      key={destinations.map((d) => d.name).join(",")}
    >
      {destinations
        .sort(
          (a, b) =>
            b.attribute_score.weighted_score - a.attribute_score.weighted_score
        )
        .map((dest, index) => (
          <div
            className={`destination-box ${
              selected_destination === dest.name ? "selected" : ""
            }`}
            key={dest.name}
            onClick={() => {
              setSelectedDestination(dest.name);
            }}
            ref={(el) => (listRefs.current[dest.name] = el)}
          >
            <h4 style={{ margin: 5 }}>
              {" "}
              {index + 1}) {capitalize(dest.name)}{" "}
              {/* 
              <a href={dest.url} target="_blank" rel="noopener noreferrer">
                {" 📚"}
              </a>
              */}
              <a
                style={{ color: "rgb(177, 243, 180)" }}
                href={`/destination_info/${dest.name}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                (Info)
              </a>
            </h4>
            {dest.attribute_score && (
              <div style={{ margin: 5, whiteSpace: "normal" }}>
                <p style={{ marginBottom: 5, marginTop: 0 }}>
                  Total score: {dest.attribute_score.weighted_score}
                </p>
                {Object.entries(dest.attribute_score)
                  .filter(([key, value]) => key !== "weighted_score")
                  .map(([key, value], index) => `${capitalize(key)}: ${value}`)
                  .join(", ")}
                {displayTempRange && dest.temp_avg && (
                  <p>
                    {dest.temp_avg !== "NA"
                      ? `Average Temp: ${dest.temp_avg}`
                      : "Temperature data not available"}
                    {/* Padding  */}{" "}
                    {dest.temp_avg === "NA" ? (
                      // No temperature is displayed for NA entries
                      " "
                    ) : dest.temp_avg < temperature.min ? (
                      <span role="img" aria-label="freezing">
                        🥶
                      </span>
                    ) : dest.temp_avg > temperature.max ? (
                      <span role="img" aria-label="hot">
                        🥵
                      </span>
                    ) : (
                      <span role="img" aria-label="calm">
                        😃
                      </span>
                    )}
                  </p>
                  // Display a green check mark if temperature of this destination is within min and max
                )}
                <span id="loc_hierarchy">
                  <p>{dest.location_hierarchy}</p>
                </span>
              </div>
            )}
          </div>
        ))}
    </div>
  );
};

export default SearchList;
