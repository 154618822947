import React, { useState } from "react";
import "../stylesheets/FeedbackComponent.css";

const FeedbackComponent = ({ show, onClose }) => {
  const [feedback, setFeedback] = useState("");

  const submitFeedback = async (feedback) => {
    // Send feedback to the Flask backend
    const response = await fetch("/api/submit_feedback/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ feedback }),
    });

    if (response.ok) {
      alert("Thank you for your feedback!");
    } else {
      alert("Error submitting feedback. Please try again later.");
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    submitFeedback(feedback);
    setFeedback("");
    onClose();
  };

  return (
    show && (
      <div className="feedback-overlay">
        <div className="feedback-container">
          <h3>User Feedback</h3>
          <form onSubmit={handleSubmit}>
            <textarea
              value={feedback}
              onChange={(e) => setFeedback(e.target.value)}
              placeholder="Describe your issue here..."
              maxLength="1000"
              rows="4"
              cols="50"
            ></textarea>
            <div className="feedback-buttons">
              <button type="submit">Submit</button>
              <button type="button" onClick={onClose}>
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    )
  );
};

export default FeedbackComponent;
