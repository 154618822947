//import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import Select from "react-select";
import AsyncSelect from "./AsyncSelect";
import makeAnimated from "react-select/animated";
import "rc-slider/assets/index.css";

const InputForm = ({
  destinations,
  setDestinations,
  temperature,
  setTemperature,
  displayTempRange,
  setDisplayTempRange,
  showRailways,
  setShowRailways,
  setIsSearchSubmitted,
}) => {
  const [region, setRegion] = useState("");

  // Attributes
  const maxVal = 100;
  const minVal = 0;

  const [attributes, setAttributes] = useState([
    { label: "Food", value: 0 },
    { label: "Beach", value: 0 },
    { label: "Resort", value: 0 },
    { label: "Island", value: 0 },
    { label: "Snow", value: 0 },
    { label: "Lake", value: 0 },
    { label: "Shopping", value: 0 },
    { label: "Religion", value: 0 },
    { label: "Surfing", value: 0 },
    { label: "Diving", value: 0 },
    { label: "Hiking", value: 0 },
    { label: "Museum", value: 0 },
    { label: "LGBT friendly", value: 0 },
  ]);

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  // Variable to track if any input in the input form has changed
  const [didFormChange, setDidFormChange] = useState("False");

  useEffect(() => {
    if (region !== "") {
      setDidFormChange(true);
    }
  }, [region, attributes, temperature, displayTempRange]);

  const queryDatabase = ({
    region,
    attributes,
    temperature,
    displayTempRange,
  }) => {
    // Construct a JSON object to be sent to flask API
    const query_data = {
      attributes_weights: attributes,
      region: region,
    };

    // Temperature
    if (displayTempRange) {
      query_data["temperature"] = temperature;
    } else {
      query_data["temperature"] = {};
    }

    if (temperature.max < temperature.min) {
      alert("Please set max temperature to be > min temperature");
      return;
    }

    //fetch("http://127.0.0.1:5000/api/search/", {
    fetch("/api/search/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(query_data),
    })
      .then((response) => response.json())
      .then((response_data) => {
        if (
          !response_data.top_dest ||
          Object.keys(response_data.top_dest).length === 0
        ) {
          alert("No match found");
          return;
        }
        const namesDest = Object.keys(response_data.top_dest);
        const fetchedDestinations = namesDest.map((name_dest) => ({
          ...response_data.top_dest[name_dest],
          name: name_dest,
        }));
        setDestinations(fetchedDestinations);
      });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (!region) {
      alert("Please input a region name");
      return;
    }

    // Check if atleast one attribute is non-zero
    const areAllAttributeValuesZero = attributes.every(
      (attribute) => attribute.value === 0
    );
    if (areAllAttributeValuesZero) {
      alert("Please select atleast one attribute");
      return;
    }

    setIsSearchSubmitted(true);
    queryDatabase({
      region,
      attributes,
      temperature,
      displayTempRange,
    });

    setDidFormChange(false);
    //console.log("form change reset");
  };

  // Multi-select
  // #################
  // React state to manage selected options
  const [selectedOptions, setSelectedOptions] = useState();

  // Array of all options (Note, this needs to by manually kept in sync with attribute list)
  const optionList = [
    { value: "beach", label: "Beach" },
    { value: "island", label: "Island" },
    { value: "lake", label: "Lake" },
    { value: "hiking", label: "Hiking" },
    { value: "snow", label: "Snow" },
    { value: "surfing", label: "Surfing" },
    { value: "diving", label: "Diving" },
    { value: "museum", label: "Museum" },
    { value: "LGBT friendly", label: "LGBT friendly" },
    { value: "resort", label: "Resort" },
    { value: "shopping", label: "Shopping" },
    { value: "food", label: "Food" },
    { value: "religion", label: "Religion" },
  ];

  function handleSelect(inputData) {
    // Update the state of selected options
    setSelectedOptions(inputData);

    // For all the selected options, update the attribute dict
    const currentOptionsLabels = inputData.map((selection) => selection.label);

    const newAttributes = [...attributes];
    for (const attr_idx in attributes) {
      if (currentOptionsLabels.includes(attributes[attr_idx].label)) {
        newAttributes[attr_idx].value = maxVal;
      } else {
        newAttributes[attr_idx].value = minVal;
      }
    }
    setAttributes(newAttributes);
  }

  const animatedComponents = makeAnimated();
  const customStyles = {
    input: (provided) => ({
      ...provided,
      color: "white",
    }),
    option: (provided, state) => ({
      ...provided,
      color: "white",
      backgroundColor: state.isFocused ? "#23332d" : provided.backgroundColor,
    }),
    multiValueRemove: (provided) => ({
      ...provided,
      color: "black",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      "&:hover": {
        color: "#44cc44",
      },
    }),
    clearIndicator: (provided) => ({
      ...provided,
      "&:hover": {
        color: "rgb(214, 87, 87)",
      },
    }),
    control: (provided) => ({
      ...provided,
      color: "white",
      backgroundColor: "black",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "white",
    }),
    menuList: (provided) => ({
      ...provided,
      backgroundColor: "black",
      height: "200px",
      zIndex: 10000,
    }),
  };

  // Class to toggle display off if we have no destinations loaded up
  const classNoDestDisp =
    destinations.length === 0 ? "NoDestinationDispNone" : "";

  return (
    <div>
      <form onSubmit={onSubmit}>
        <div className="form-control">
          <AsyncSelect setRegion={setRegion} />
        </div>
        {
          //<DropDownAttributeSlider attributes={attributes} />
        }
        <div className="dropdown-container">
          <Select
            className="react-dropdown-container"
            options={optionList}
            components={animatedComponents}
            placeholder="Select attributes"
            value={selectedOptions}
            onChange={handleSelect}
            isSearchable={true}
            styles={customStyles}
            noOptionsMessage={() => "No match, select from drop-down list."}
            isMulti
          />
        </div>

        <div className={`inputTempCheckBox ${classNoDestDisp}`}>
          <input
            type="checkbox"
            checked={displayTempRange}
            onChange={(e) => setDisplayTempRange(e.target.checked)}
            style={{ padding: "0" }}
          />
          <span> Temperature Filter </span>
          <span
            style={{
              display: displayTempRange ? "block" : "none",
            }}
          >
            Min
            <input
              type="number"
              value={temperature.min}
              onChange={(event) =>
                setTemperature({
                  ...temperature,
                  min: parseFloat(event.target.value),
                })
              }
            />
            Max
            <input
              type="number"
              value={temperature.max}
              onChange={(event) =>
                setTemperature({
                  ...temperature,
                  max: parseFloat(event.target.value),
                })
              }
            />
            <label>
              <span style={{ paddingRight: "10px" }}>Travel Month</span>
              <select
                value={temperature.month}
                onChange={(event) =>
                  setTemperature({
                    ...temperature,
                    month: event.target.value,
                  })
                }
              >
                {months.map((month) => (
                  <option key={month} value={month}>
                    {month}
                  </option>
                ))}
              </select>
            </label>
          </span>
        </div>

        <div className={`showRailwayCheckBox ${classNoDestDisp}`}>
          <input
            type="checkbox"
            checked={showRailways}
            onChange={(e) => setShowRailways(e.target.checked)}
            style={{ padding: "0" }}
          />
          Show Railways
        </div>
        <input
          type="submit"
          value="Search"
          className={`submitBtn btn btn-block ${
            didFormChange === true ? "btn-ready-query" : ""
          }`}
        />
      </form>
    </div>
  );
};

InputForm.defaultProps = {
  title: "Task Tracker",
};

//InputForm.propTypes = {
//  title: PropTypes.string.isRequired,
//};

export default InputForm;
